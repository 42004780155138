@import './colors';

@each $colorName, $matColor in $material-colors {
  $color: map-get($matColor, 700);
  $foreground: map-get(map-get($matColor, contrast), 700);

  // Background color
  .#{''+$colorName} {
    background-color: $color !important;
    color: $foreground !important;
  }

  // Text color
  .#{'text-'+$colorName+''} {
    color: $color;
  }
}

.light-grey {
  $grey: map-get($material-colors, grey);
  background-color: map-get($grey, 300) !important;
  color: map-get(map-get($grey, contrast), 500) !important;
}
