/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
@use '@angular/material' as mat;

@include mat.core();

body {
  --theme-primary-50: #E6E7EF;
  --theme-primary-100: #C1C4D7;
  --theme-primary-200: #989DBD;
  --theme-primary-300: #6F76A3;
  --theme-primary-400: #50588F;
  --theme-primary-500: #313B7B;
  --theme-primary-600: #2C3573;
  --theme-primary-700: #252D68;
  --theme-primary-800: #1F265E;
  --theme-primary-900: #1F265E;
  --theme-primary-A100: #8690FF;
  --theme-primary-A200: #5361FF;
  --theme-primary-A400: #2032FF;
  --theme-primary-A700: #071BFF; 
  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #ffffff;
  --theme-primary-contrast-400: #ffffff;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff; 
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #ffffff;
  --theme-primary-contrast-A200: #ffffff;
  --theme-primary-contrast-A400: #ffffff;
  --theme-primary-contrast-A700: #ffffff;
}

$mat-primary: (
  50 : var(--theme-primary-50),
  100 : var(--theme-primary-100),
  200 : var(--theme-primary-200),
  300 : var(--theme-primary-300),
  400 : var(--theme-primary-400),
  500 : var(--theme-primary-500),
  600 : var(--theme-primary-600),
  700 : var(--theme-primary-700),
  800 : var(--theme-primary-800),
  900 : var(--theme-primary-900),
  A100 : var(--theme-primary-A100),
  A200 : var(--theme-primary-A200),
  A400 : var(--theme-primary-A400),
  A700 : var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700),
  )
);

body {
  --theme-accent-50: #E9F4F4;
  --theme-accent-100: #C9E4E4;
  --theme-accent-200: #A5D2D2;
  --theme-accent-300: #81C0BF;
  --theme-accent-400: #66B3B2;
  --theme-accent-500: #57c4c3;
  --theme-accent-600: #449D9C;
  --theme-accent-700: #3B9392;
  --theme-accent-800: #338A89;
  --theme-accent-900: #237978;
  --theme-accent-A100: #B9FFFE;
  --theme-accent-A200: #86FFFE;
  --theme-accent-A400: #53FFFD;
  --theme-accent-A700: #3AFFFD; 
  --theme-accent-contrast-50: #000000;
  --theme-accent-contrast-100: #000000;
  --theme-accent-contrast-200: #000000;
  --theme-accent-contrast-300: #000000;
  --theme-accent-contrast-400: #000000;
  --theme-accent-contrast-500: #000000;
  --theme-accent-contrast-600: #000000; 
  --theme-accent-contrast-700: #000000;
  --theme-accent-contrast-800: #000000;
  --theme-accent-contrast-900: #000000;
  --theme-accent-contrast-A100: #000000;
  --theme-accent-contrast-A200: #000000;
  --theme-accent-contrast-A400: #000000;
  --theme-accent-contrast-A700: #000000;
}

$mat-accent: (
  50 : var(--theme-accent-50),
  100 : var(--theme-accent-100),
  200 : var(--theme-accent-200),
  300 : var(--theme-accent-300),
  400 : var(--theme-accent-400),
  500 : var(--theme-accent-500),
  600 : var(--theme-accent-600),
  700 : var(--theme-accent-700),
  800 : var(--theme-accent-800),
  900 : var(--theme-accent-900),
  A100 : var(--theme-accent-A100),
  A200 : var(--theme-accent-A200),
  A400 : var(--theme-accent-A400),
  A700 : var(--theme-accent-A700),
  contrast: (
    50: var(--theme-accent-contrast-50),
    100: var(--theme-accent-contrast-100),
    200: var(--theme-accent-contrast-200),
    300: var(--theme-accent-contrast-300),
    400: var(--theme-accent-contrast-400),
    500: var(--theme-accent-contrast-500),
    600: var(--theme-accent-contrast-600),
    700: var(--theme-accent-contrast-700),
    800: var(--theme-accent-contrast-800),
    900: var(--theme-accent-contrast-900),
    A100: var(--theme-accent-contrast-A100),
    A200: var(--theme-accent-contrast-A200),
    A400: var(--theme-accent-contrast-A400),
    A700: var(--theme-accent-contrast-A700),
  )
);

body {
  --ios-install-bg: #5a5858;
  --popup-background-color: #e5e6e8;
  --theme-warn-500: #d32f2f;
  overscroll-behavior-y: contain;
}

$mat-warn: mat.$red-palette;

$theme-primary: mat.define-palette($mat-primary);
$theme-accent: mat.define-palette($mat-accent);
$theme-warn: mat.define-palette($mat-warn);

$theme-dark-primary: mat.define-palette($mat-primary, 500, 200, 800);
$theme-dark-accent: mat.define-palette($mat-accent, 400, 100, 700);
$theme-dark-warn: mat.define-palette($mat-warn, 300, 100, 600);

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

$dark-Theme: mat.define-dark-theme((
  color: (
    primary: $theme-dark-primary,
    accent: $theme-dark-accent,
    warn: $theme-dark-warn,
  )
));

// Theme Init
@include mat.all-component-themes($theme);
@include mat.typography-hierarchy(mat.define-typography-config());

.mat-mdc-raised-button.mat-primary:not(:disabled) {
  color: mat.get-color-from-palette($theme-primary, '500-contrast') !important;
}

.mat-mdc-raised-button.mat-accent:not(:disabled) {
  color: mat.get-color-from-palette($theme-accent, '500-contrast') !important;
}

.mat-mdc-raised-button.mat-warn:not(:disabled) {
  color: mat.get-color-from-palette($theme-warn, '500-contrast') !important;
}

.mat-mdc-progress-bar.mat-primary .mdc-linear-progress__primary-bar {
  background-color: mat.get-color-from-palette($theme-primary, 100) !important;
}

.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: mat.get-color-from-palette($theme-accent, 100) !important;
}

.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: mat.get-color-from-palette($theme-warn, 100) !important;
}

.dark-theme {
  @include mat.all-component-colors($dark-Theme);

  --text-color: #e6e5ef;
  --popup-background-color: #5a5858;
  --card-background-color: #424242;
  --background-color: #33313a;

  color: var(--text-color);
  // background: var(--background-color);
}

.ngx-mat-colorpicker-content  {
  background-color: var(--popup-background-color) !important;
}

mat-card, .mat-mdc-dialog-surface {
  border-radius: 13px !important;
  overflow: hidden; 
}

.light-theme {
  mat-card {
    background: #f7f7f7 !important;
  }
}

.mat-card {
  mat-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Roboto';
  }
}

.text-primary {
  color: map-get($mat-primary, 500);
}

.text-accent {
  color: map-get($mat-accent, 500);
}

.text-warn {
  color: map-get($mat-warn, 500);
}

.dark-theme {
  .text-primary {
    color: map-get($mat-primary, 200);
  }
}

.primary {
  background: map-get($mat-primary, 500) !important;
  color: map-get(map-get($mat-primary, contrast), 500) !important;
}

.accent {
  background: map-get($mat-accent, 500) !important;
  color: map-get(map-get($mat-accent, contrast), 500) !important;
}

.warn {
  background: map-get($mat-warn, 500) !important;
  color: map-get(map-get($mat-warn, contrast), 500) !important;
}

.primary-bg {
  background: map-get($mat-primary, 500) !important;
}

.accent-bg {
  background: map-get($mat-accent, 500) !important;
}

.warn-bg {
  background: map-get($mat-warn, 500) !important;
}

.primary-text {
  color: map-get($mat-primary, 500) !important;
}

.accent-text {
  color: map-get($mat-accent, 500) !important;
}

.warn-text {
  color: map-get($mat-warn, 500) !important;
}
