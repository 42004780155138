.overflow-hidden {
  overflow: hidden !important;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.flex-grow {
  flex-grow: 1;
}

.space-between {
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-mdc-paginator-page-size-label {
  display: none;
}
.mat-mdc-dialog-content {
  overflow-x: hidden !important;
}
.mat-mdc-dialog-content a {
  word-break: break-all;
}
td a {
  word-break: break-all !important;
}

@media (max-width: 599px) {
  .desktop {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .mobile {
    display: none !important;
  }
}