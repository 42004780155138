$spaces: (
  333: .333rem,
  '05': .5rem,
  '0': 0rem,
  1: 1rem,
  2: 2rem,
  '1v': var(--selfservice-spacing-1),
  '05v': var(--selfservice-spacing-05),
  '03v': var(--selfservice-spacing-03),
);

$locations: (
  top: 't',
  bottom: 'b',
  left: 'l',
  right: 'r'
);

@each $space, $value in $spaces {
  @each $location, $char in $locations {
    .p#{$char}-#{$space} {
      padding-#{$location}: $value !important;
    }
    .m#{$char}-#{$space} {
      margin-#{$location}: $value !important;
    }
  }
  .p-#{$space} {
    padding: $value !important;
  }
  .m-#{$space} {
    margin: $value !important;
  }
}

